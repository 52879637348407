


























import { FaceControlRegexpConditions } from '@/includes/types/FaceControl.types'
import { InputSetups } from '../../../mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({})
export default class RegexConditionView extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() model!:FaceControlRegexpConditions

}
